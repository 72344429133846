import { createApp } from 'vue';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import App from './App.vue';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCRcOP87S2Zo0DIMT7RX253QCNVCntOGzc',
  authDomain: 'dfluid-outlay-56e7c.firebaseapp.com',
  projectId: 'dfluid-outlay-56e7c',
  storageBucket: 'dfluid-outlay-56e7c.appspot.com',
  messagingSenderId: '723557360831',
  appId: '1:723557360831:web:e3994c3472e51cfc7ed20a',
};

createApp(App)
  .use(initializeApp(firebaseConfig))
  .use(getStorage(initializeApp(firebaseConfig)))
  .use(ElementPlus)
  .use(router)
  .mount('#app');
