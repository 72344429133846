<template>
  <router-view />
</template>

<script>
export default {};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  background-color: #191817;
  color: white;
}
</style>
