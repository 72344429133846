<template>
  <div class="page">
    <div class="page-wrapper">
      <h2 class="logo"><strong>디플루이드</strong> 법인카드 지출관리</h2>
      <h3>Login</h3>
      <p class="desc">
        디플루이드 임직원만 접근하실 수 있습니다.<br />
        그룹웨어 이메일 주소와 비밀번호를 입력하세요.
      </p>
      <form @submit.prevent="login">
        <input v-model="email" placeholder="Email" type="email" />
        <input v-model="password" placeholder="Password" type="password" />
        <p v-if="errorMessage" class="errorMessage">
          이메일 주소와 비밀번호를 다시 확인하세요.
        </p>
        <button type="submit">Next</button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const router = useRouter();
    const auth = getAuth();
    const errorMessage = ref(false);

    watch([email, password], () => {
      errorMessage.value = false;
    });

    const login = () => {
      signInWithEmailAndPassword(auth, email.value, password.value)
        .then((userCredential) => {
          const { user } = userCredential;
          // console.log(user);
          router.push({
            name: 'OutlayList',
          });
        })
        .catch((error) => {
          errorMessage.value = true;
          // console.log(error.message);
        });
    };

    return {
      login,
      email,
      password,
      errorMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  overflow: hidden;
  position: absolute;
  top: 0;

  .page-wrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    width: 500px;

    .logo {
      font-family: 'NanumSquare', sans-serif;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: -0.05em;
      margin: 0;
      line-height: 60px;
      color: #fcfbfa;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      strong {
        font-weight: 900;
      }
    }

    h3 {
      font-family: 'exo 2', sans-serif;
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 0.1em;
      color: #999693;
      text-transform: uppercase;
    }

    .desc {
      font-size: 12px;
      letter-spacing: -0.05em;
      color: #999693;
      line-height: 1.5;
      margin: 0;
      text-align: center;
    }

    form {
      width: 100%;
      padding: 36px 24px 0;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      position: relative;

      input {
        font-family: 'exo 2', sans-serif;
        margin: 7px 0;
        font-size: 16px;
        font-weight: 300;
        padding: 15px;
        border-radius: 7px;
        border: 0.5pt solid #393735;
        background: transparent;
        color: #fcfbfa;
        &:focus {
          border-color: #999693;
          outline: none;
        }
      }

      .errorMessage {
        position: absolute;
        top: 162px;
        left: 0;
        justify-content: center;
        display: flex;
        font-family: 'NanumSquare', sans-serif;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.05em;
        width: 100%;
        color: #f1b99d;
      }

      button {
        font-family: 'exo 2', sans-serif;
        margin-top: 43px;
        cursor: pointer;
        padding: 0 0 3px;
        height: 51px;
        border: 0;
        border-radius: 7px;
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0.1em;
        background-color: #393735;
        color: #999693;
        text-transform: lowercase;

        &:hover,
        &:focus,
        &:active {
          background-color: #7ac5a2;
          color: #191817;
        }
      }
    }
  }
}
</style>
