import { createRouter, createWebHistory } from 'vue-router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Login from '../pages/Login.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/outlaylist',
    name: 'OutlayList',
    component: () =>
      import(/* webpackChunkName: "outlaylist" */ '../pages/OutlayList.vue'),
    meta: {
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // const currentUser = getAuth().currentUser;
  const authRequired = to.matched.some((record) => record.meta.authRequired);

  onAuthStateChanged(getAuth(), (user) => {
    if (authRequired) {
      next(user ? null : { name: 'Login' });
    } else {
      next();
    }
  });
});

export default router;
